@import './../Components/variables'

.MuiStack-root 
  padding-top: 0 !important

.activation-form-textarea
  margin-bottom: 8px !important

.activations-list
  .MuiListItem-root
    border: 1px solid $gray-200
    padding: 8px
    border-radius: 16px
    margin-bottom: 16px