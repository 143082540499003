
@import './../Components/variables'
.login-wrapper
  background: url('../assets/images/login-bg-default.png')  no-repeat
  background-size: cover
  height: 100vh
  transition: 0.5s all

.login-box
  height: 100vh
  text-align: center

  .login-box-wrapper
    background: $white
    border-radius: 16px
    margin-right: 8%
    padding: 32px

    img
      width: auto
      max-height: 60px

    .MuiTypography-caption
      margin-top: 16px

    .signin-other
      border-top: 1px solid $gray-200
      margin-top: 38px
      font-size: 12px
      color: $gray-700

      .MuiTypography-caption
        display: inline-block
        position: relative
        margin: 0
        top: -12px
        background-color: $white

      .button-bepass-id
        display: block
        width: 80%
        margin: 8px auto
        border-radius: 16px
        background-color: $gray-200
        padding: 8px

        img
          width: 50%
          height: auto
          display: inline-block
          vertical-align: middle
  h2
    color: $primary
  .MuiTypography-caption
    font-size: 14px
// modal form
.login-form-content
  margin-top: 16px
  button
    width: 100%
    background-color: $primary
    color: $white
    padding: 8px 16px
    border-radius: 32px

  .MuiOutlinedInput-root
    border-radius: 8px

  .icon-success,
  .icon-error
    // width: 40px
    // height: 40px
    text-align: center
    font-size: 16px
    color: $primary
    svg
      width: 30px
      height: 30px
      background-color: $primary
      padding: 4px
      fill: $white
      border-radius: 100%
      vertical-align: middle
      margin-right: 16px

  .icon-error
    color: $error

    svg
      background-color: $error

  .loading-handler
    width: 30px
    height: 30px
    color: $primary
    position: absolute,
    top: -2%,
    left: 44%,

.login-company-list,
.login-app-list
  list-style: none
  margin: 0
  padding: 0
  
  li
    display: inline-block
    margin: 16px


.login-app-list
  li
    display: block
    font-size: 16px
    text-align: left
    padding: 8px
    border-bottom: 1px solid $gray-200
    vertical-align: middle
    margin: 0
    

    &:hover
      cursor: pointer
      background-color: $gray-100
      border-radius: 16px

    i
      width: 20px
      height: 22px
      display: inline-block
      margin: 0
      padding: 2px 4px 4px 4px
      vertical-align: middle
      background-color: $primary
      border-radius: 100%
      text-align: center
      margin-right: 8px

      svg
        width: 80%
        vertical-align: middle
        fill: $white

    &:last-child
      border: none



//Bepassid
.bepass-id-wrapper
  max-width: 400px  
  .bepassid-form
    padding: 32px

  .face-handler
    width: 100%
    height: 400px
    text-align: center
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column

    .MuiTypography-caption
      display: block
      margin-top: 16px
      font-size: 14px
      color: $gray-700
  
  .face-handler-success
    width: 100%
    height: 100%
    color: $gray-800
    position: relative
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column

    .MuiTypography-h3
      font-size: 2.2em

    span
      color: $primary

    .conffeti-wrap
      position: absolute
      width: 100%
      height: 100%
      left: 0
      top: 0

.login-wrapper:has(.peru)
  background-image: url('../assets/images/login-bg-mengo.jpg')
  background-size: cover
  background-position: center center



// mobile
@media (max-width: 768px)
  .login-box
    width: 100%

    .login-box-wrapper
      width: 100%
      margin: 0 20px

  header.MuiAppBar-positionFixed
    margin: 0
    right: 10%
