@import  '../variables'

.container-auto-capture
  position: relative

  .video
    position: relative
    width: 100%
    height: 100%
    object-fit: cover
    transform: scaleX(-1)

  .custom-svg
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)

  .centered
    stroke: green

  .not-centered
    stroke: red


  .frame-selfie
    width: 50%
    height: 60%

    position: absolute
    left: 25%
    top: 20%
    z-index: 3

    img
      width: 100%

    &.centered img
      filter: invert(1)


