@import '../variables'

.user-card
  border-radius: 16px !important

.user-card-header
  background-color: $gray-800
  padding: 8px 16px
  position: relative

  .MuiTypography-h3
    font-size: 20px
    line-height: 30px
    color: $white

.user-card-header-icons
  width: 150px
  text-align: right

  i
    display: inline-block
    width: 30px
    height: 30px
    background-color: $primary
    line-height: 44px
    margin-left: 8px
    border-radius: 4px
    text-align: center

    &:hover
      background-color: darken($primary, 10% )
      cursor: pointer

    svg
      fill: $white

.user-card-content-details
  background-color: $gray-200
  list-style: none
  margin: 0
  padding: 16px

  li
    display: inline-block
    font-size: 14px
    line-height: 24px
    color: $gray-800
    border-right: 1px solid $gray-400
    margin-right: 16px
    padding-right: 16px

    &:last-child
      border: none

.user-card-image-wrap
  position: relative

  &.solo-view
    display: flex
    text-align: center
    align-items: center
    height: 100%
    min-height: 400px
    justify-content: center
    background-color: $gray-100

  img
    width: 100%
    border-radius: 8px
    overflow: hidden

  i
    display: inline-block
    width: 30px
    height: 30px
    background-color: $primary
    line-height: 44px
    border-radius: 4px
    text-align: center

    // colocar default em component
    position: absolute
    top: 8px
    right: 8px

    &:hover
      background-color: darken($primary, 10% )
      cursor: pointer

    svg
      fill: $white


.user-card-info-action
  padding: 16px
  border-radius: 8px
  background-color: $gray-100
  border: 1px solid $gray-300
  margin-bottom: 8px

  .MuiTypography-h4
    font-size: 18px
    line-height: 30px
    color: $black

    // 12/20
    //   10

.user-card-info-timeline
  list-style: none
  margin: 0
  padding: 0

  li
    display: flex
    justify-content: space-between
    font-size: 14px
    line-height: 20px
    color: $gray-800
    margin-top: 25px
    position: relative

    &::before
      content: " "
      display: block
      height: 30px
      border-left: 1px dashed $gray-500
      position: absolute
      left: 12px
      top: -30px

    &:first-child
      margin-top: 8px
      &::before
        display: none

    .MuiTypography-h6
      font-size: 12px
      line-height: 20px
      font-weight: normal
      color: $black

      i
        display: inline-block
        width: 25px
        height: 25px
        background-color: $white
        line-height: 40px
        border-radius: 100% 
        text-align: center
        vertical-align: middle
        margin-right: 16px

        svg
          width: 80%
          fill: $gray-500
          
    span
      font-size: 10px
      line-height: 20px
      color: $gray-700

    &.done
      i
        background-color: $primary
        svg
          fill: $white
      &::before
        border-color: $primary

    // &:last-child
    //   border: none

.user-card
  .default-button
    display: block
    width: 100%
    border-color: $gray-600
    color: $gray-800
    margin-top: 4px
    font-weight: normal
    text-transform: none
    font-size: 16px
    padding: 3px 0
    border-radius: 8px

    &:hover
      background-color: $primary
      color: $white




// Modal Aprovação manual

.custom-stepper
  display: flex
  justify-content: space-between
  padd
.custom-step
  width: 25%
  padding: 8px 18px 8px 0
  text-align: center
  color: $gray-600
  font-size: 14px
  border-bottom: 1px solid $gray-500

  &.done
    color: $black
    svg
      width: 16px
      height: 16px
      opacity: 1
      background-color: $primary
      fill: $white
      border-radius: 100%

  &.active
    background-color: $primary
    color: $white
 
  svg
    width: 16px
    height: 16px
    vertical-align: middle
    fill: $primary
    margin-right: 8px
    opacity: 0

.upload-field
  display: flex
  flex-direction: row
  margin: 16px
  background-color: $gray-100
  border: 1px dashed $gray-300
  border-radius: 8px
  border-width: 2px
  justify-content: center
  padding: 8px

  input
    opacity: 0
    width: 0
    height: 0

  svg
    width: 30px
    height: auto
    margin-right: 16px

  .MuiTypography-caption
    font-size: 16px
    line-height: 28px

.user-approved-action-button
  width: 100%
  display: flex
  justify-content: center
  margin: 32px 0

  .MuiButton-textPrimary
    background-color: $primary
    color: $white
    margin: 0 16px
    padding: 8px 16px

    &.Mui-disabled
      display: none

.modal-approval-result
  display: flex
  justify-content: center
  align-items: center
  min-height: 400px

  .icon-success
    // width: 40px
    // height: 40px
    text-align: center
    font-size: 16px
    color: $primary
    svg
      width: 50px
      height: 50px
      background-color: $primary
      padding: 4px
      fill: $white
      border-radius: 100%
      vertical-align: middle
      margin-right: 16px

// uploader caption
.upload-file-wrapper
  margin: 16px
  padding: 16px
  border: 1px solid $gray-200
  border-radius: 8px

  .upload-file-caption
    display: flex
    justify-content: space-between

  .MuiTypography-caption
    font-size: 16px
    display: inline-block

    span
      font-size: 12px
      display: block


@media (max-width: 768px)
  .user-card
    margin-bottom: 80px
  .user-card-content-details
    li
      display: block
      width: 100%
      border: 0
      margin: 0 0 4px 0 
      padding: 0 0 4px 0 
      border-bottom: 1px solid $gray-400
  .css-15kdfbc-MuiButtonBase-root-MuiButton-root
    display: none !important