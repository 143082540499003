@import './../Components/variables'

.arena-template-wrapper
  position: relative
  width: 100%
  background-color: $gray-100
  overflow: hidden
  border-radius: 16px

  .MuiTypography-h2
    background-color: $gray-800
    padding: 8px 16px
    position: relative
    font-size: 20px !important
    line-height: 30px
    color: $white

  img
    display: block
    width: 57%
    margin: 13% auto 7% auto

  ul.arena-template-turnstile
    list-style: none
    width: 100%

    li
      display: block
      position: absolute
      width: 30px
      height: 30px
      background-color: $gray-300
      z-index: 2
      background-size: cover
      border-radius: 100%
      background-repeat: no-repeat
      transition: 0.4s  all

    .arena-entrance
      border: 2px solid $primary

    .arena-wront-gate
      background-color: $secondary

    .arena-denied
      background-color: $error

    .MuiTypography-caption
      width: 100%
      text-align: center
      font-size: 9px
      font-weight: bold
      color: $gray-800
      position: absolute
      bottom: -16px

.arena-entrance-counter
  width: 46%
  margin: 0 2%
  text-align: right

  .MuiLinearProgress-root
    background-color: $primary-200
  .MuiLinearProgress-bar
    background-color: $primary

  .MuiTypography-h1
    font-size: 4.1em !important
    line-height: 70px
    color: $primary
    margin-bottom: 0

  &.waiting

    .MuiLinearProgress-root
      background-color: $secondary-200
    .MuiLinearProgress-bar
      background-color: $gray-300

    .MuiTypography-h1
      color: $gray-500

  .MuiTypography-caption
    font-size: 14px
    color: $gray-600

.arena-graph-entrances
  width: 100%
  height: 400px

.arena-counters
  display: flex
  flex-flow: 2
  flex-wrap: wrap