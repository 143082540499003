@import './../Components/variables'

.event-evolution-chart-wrapper
  height: 400px
  width: 100%

.event-select
  margin: 16px 0

  .MuiOutlinedInput-notchedOutline
    border-radius: 8px

.event-main-display
  background-color: $gray-200
  border-radius: 16px
  padding: 16px
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  text-align: center

  .action-row
    width: 100%
    text-align: right

    span
      font-size: 12px
      color: $gray-600
      vertical-align: super

    i
      display: inline-block
      width: 30px
      height: 30px
      background-color: $primary
      line-height: 44px
      margin-left: 8px
      border-radius: 4px
      text-align: center

      &:hover
        background-color: darken($primary, 10% )
        cursor: pointer

      svg
        fill: $white

  h1
    font-size: 20px  !important
    font-weight: normal !important
    margin: 16px 0

  .event-card-game
    width: 68%
    display: flex
    justify-content: space-between
    background-color: $white
    border-top: 4px double $primary 
    border-bottom: 4px double $primary
    padding: 16px 0

    h3
      font-size: 20px  !important
      font-weight: 700 !important
      text-transform: uppercase

    .MuiTypography-caption
      color: $primary
      font-size: 16px
      font-weight: 700

    .game-event-details
      width: 70%
    .game-club
      width: 70px
      height: 70px

      &:first-child
        margin-left: -19%
        margin-right: 60px

      img
        width: 250%
        height: auto
        background-color: $white
        filter: drop-shadow(0 20px 13px rgb(0 0 0 / 0.03)) drop-shadow(0 8px 5px rgb(0 0 0 / 0.08))
        border-radius: 100%
        padding: 8px
        margin-top: -100%

  .button-group
    margin-top: 16px

  button
    background-color: $primary
    text-transform: capitalize
    box-shadow: none
    padding: 4px 16px !important
    display: inline-block !important
    margin: 0 16px

    &.MuiButton-colorInfo
      background-color: $secondary

  .Mui-disabled
    display: inline-block !important
    background-color: $primary-400 !important
    color: $white !important


.event-sync-symmary
  border-radius: 16px
  border: 1px solid $gray-400
  overflow: hidden
  margin: 32px 0

  h2
    font-size: 20px !important
    width: 100%
    background-color: $gray-800
    color: $white
    padding: 8px 16px

  .MuiTableCell-head
    font-size: 12px
    color: $gray-700
    background-color: $gray-100

  .MuiTableRow-root
    th, td
      text-align: center
      border-left: 1px solid $gray-400 !important

    .MuiTableCell-alignLeft
      text-align: left

    th:first-child
      border-left: 0 !important

    &.error
      color: $error

.event-evolution-chart
  width: 100%
  min-height: 350px

  padding: 16px
  background-color: $white
  border: 1px solid $gray-200
  border-radius: 16px
  margin: 24px 0
  position: relative

  .MuiTypography-caption
    position: absolute
    right: 24px
    bottom: 24px
    font-size: 14px
    color: $gray-600
  h3
    font-size: 18px
    color: $gray-800
  .event-evolution-chart-legend
    font-size: 12px
    display: inline-block
    margin-right: 32px

    svg
      vertical-align: middle

    &.ticket-sold
      svg
        fill: $secondary-300

.sync-summary-header
  display: flex
  justify-content: space-between
  align-items: center

  button
    font-size: 16px
    background-color: $primary
    text-transform: capitalize
    box-shadow: none
    padding: 4px 16px !important
    display: inline-block !important


.sync-symmary-status
  list-style: none
  border: 1px solid $gray-200
  margin: 0
  padding: 8px 0
  border-radius: 32px

  font-size: 14px
  font-weight: bold

  li
    display: inline-block
    margin: 0 16px
    position: relative
    padding-left: 24px
    

    &::before
      content: " "
      display: block
      position: absolute
      left: 0
      top: 0
      width: 20px
      height: 20px
      border-radius: 4px
    &.status-idle::before
      background-color: $gray-200
    &.status-waiting::before
      background-color: #FFF2B8
    &.status-done::before
      background-color: $primary
    &.status-offline::before
      background-color: #FF7119

.accordion-sync
  border-radius: 16px !important
  border: 1px solid $gray-200
  padding: 0 !important
  margin: 0 0 32px 0 !important
  overflow: hidden
  box-shadow: none
  --Paper-shadow: none !important

  .MuiAccordionSummary-root,
  .MuiAccordionSummary-contentGutters
    padding: 0 !important
    margin: 0 !important
    min-height: auto !important

  .accordion-sync-symmary

    width: 25%
    display: flex
    justify-content: space-between

    background-color: $gray-800
    padding: 8px 16px
    position: relative

    font-size: 20px
    line-height: 30px
    color: $white

    &.event-list-header
      width: 50%

    svg
      font-size: 32px

  .MuiAccordionDetails-root
    padding: 16px 8px

  .accordion-sync-list
    width: 100%
    list-style: none
    padding: 0
    margin: 0
    font-size: 22px
    display: flex
    align-items: center
    flex-wrap: wrap

    li
      max-width: 120px
      width: 12%
      border: 1px solid $gray-200
      background-color: $gray-100
      border-radius: 16px
      display: flex
      flex-direction: column
      text-align: center
      justify-content: space-between
      margin: 8px

      &.idle
        background-color: $gray-200
      &.waiting
        background-color: #FFF2B8
      &.offline
        background-color: #FF7119

      span
        margin: 0 10% 8px 10%
        font-size: 14px
        background-color: $white
        border-radius: 0 0 8px 8px
        display: block
        padding: 4px 0
        color: $gray-700
      
      h3
        font-size: 22px
        font-weight: bold

.accordion-sync-summary-list
    width: 100%
    list-style: none
    padding: 0
    margin: 0
    font-size: 14px
    display: flex
    align-items: center
    background-color: $gray-100
    border-bottom: 1px solid $gray-200

    li
      display: flex
      align-items: center
      position: relative
      border-left: 1px solid $gray-200
      margin-left: 16px
      padding-left: 16px
      height: 100%
      
      &:first-child
        border: 0

      h3
        font-size: 18px
        font-weight: bold
        color: $gray-700
      span
        font-size: 14px
        font-weight: normal
        margin-left: 16px

@media (max-width: 768px)
  .event-main-display
    .action-row
      text-align: center

    .event-card-game
      width: 100%
      padding: 0
      .game-event-details
        width: 100%
        padding: 16px 0

        h3
          font-size: 16px !important

    .button-group
      width: 100%

      button
        width: 100%
        margin: 0 0 8px 0 


  .sync-summary-header
    flex-wrap: wrap

    .sync-symmary-status
      width: 100%
      border-radius: 0
      border: 0
      border-bottom: 1px solid $gray-200
    
      li
        display: block
        width: 100%
        margin-bottom: 8px

  .accordion-sync
    width: 100%

    .MuiAccordionSummary-content
      width: 100%
      display: block
    
    .accordion-sync-symmary,
    .event-list-header
      width: 100%
      font-size: 16px

    .accordion-sync-summary-list
      flex-wrap: wrap
      li
        width: 100%
        border: 0
        border-bottom: 1px solid $gray-200
        margin: 8px 0
        h3
          font-size: 14px
          span
            font-size: 12px
            margin-left: 4px

    .accordion-sync-list li
      width: 27%

      h3
        font-size: 14px
        span
          font-size: 10px

    

.accordion-user-sync-list
  width: 100%
  list-style: none
  padding: 0
  margin: 0
  font-size: 22px
  display: flex
  align-items: center
  flex-wrap: wrap

  li
    border: 1px solid $gray-200
    background-color: $gray-100
    border-radius: 16px
    font-size: 12px
    padding: 2px 8px
    margin: 4px
    font-weight: bold

    &.idle
      background-color: $gray-200
    &.waiting
      background-color: #FFF2B8
    &.offline
      background-color: #FF7119
    &.done
      background-color: $primary