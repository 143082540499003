@import '../variables'

.besafe-image-wrap
  position: relative
  border: 1px solid $gray-200
  border-radius: 8px
  overflow: hidden
  height: 265px

  &:hover
    img
      display: none

    .MuiList-root
      display: block
  img
    width: 100%
    display: block

  .MuiList-root
    display: none
    li
      font-size: 14px
      flex-direction: column
      justify-content: flex-start
      align-items: start

      span
        color: $primary
        font-weight: 800
        display: block
