@import './../Components/variables'

.besafe-wrapper
  margin: 32px 0

  .besafe-select-form-button
    width: 100%
    background-color: $primary
    color: $white
    padding: 8px 16px

  .besafe-image-uploader
    width: 100%
    height: 100%
    background-color: $gray-100
    border: 1px solid $gray-200
    border-radius: 16px
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    font-size: 14px
    color: $gray-700

    svg
      font-size: 3.5em
      color: $primary
      margin-bottom: 16px

.besafe-user-list
  button
    margin-left: 8px
    padding: 4px
    font-size: 11px
    line-height: 14px
    border-color: $primary
    color: $primary

.pagination-wrapper
  margin-top: 32px

  .MuiPagination-ul
    justify-content: center