@import './../Components/variables'

.user-search
  margin-bottom: 32px

  .user-search-form
    width: 100%

    svg
      width: 28px
      height: auto

    .MuiOutlinedInput-root
      border-radius: 8px

    .MuiPaper-elevation
      margin-top: 16px

// calendar
.react-daterange-picker
  width: 100%
  .react-daterange-picker__wrapper
    width: 100%
    border-radius: 8px
    padding: 16px 4px
    font-size: 14px
    color: $gray-700
    background-color: $white
    border: 1px solid $gray-200
    margin: 23px 0 16px 0
    button
      display: none

.react-daterange-picker__calendar
  width: 100% !important
.react-calendar
  width: 100% !important
  border-radius: 8px
  padding: 8px
  font-size: 16px
  color: $gray-700
  background-color: $white
  border: 1px solid $gray-200

.react-calendar__month-view__days
  .react-calendar__tile
    padding: 4px
    margin: 4px 0

  .react-calendar__tile--hasActive,
  .react-calendar__tile--now
    background-color: $white
    color: $primary

  .react-calendar__tile--active
    background-color: $primary !important
    color: $white

  .react-calendar__tile--rangeStart
    border-radius: 16px 0 0 16px !important

  .react-calendar__tile--rangeEnd
    border-radius: 0 16px 16px 0 !important

  .react-calendar__tile--rangeBothEnds
    border-radius: 16px !important

  .react-calendar__month-view__days__day--weekend
    color: inherit


.MuiBarElement-root
  // background-color: linear-gradient(190deg, rgba(200,200,200,1) 0%, rgba(255,255,255,1) 100%) !important
  // fill: #f0f !important
  // fill: linear-gradient(190deg, rgba(200,200,200,1) 0%, rgba(255,255,255,1) 100%) !important
  fill: linear-gradient(#f69d3c, #00ff00) !important
  background: linear-gradient(#f69d3c, #ff00ff)  !important




// graphi!

.graph-biometries-counter
  min-height: 420px
  h3
    font-size: 18px
    color: $gray-800

.graph-wrapper
  width: 100%
  min-height: 350px
  display: flex
  padding: 32px 32px 64px 32px
  background-color: $white
  border: 1px solid $gray-200
  border-radius: 16px
  margin: 24px 0
  position: relative

  .MuiTypography-caption
    position: absolute
    right: 24px
    bottom: 24px
    font-size: 14px
    color: $gray-600

.graph-custom-legend
  width: 18%
  display: flex
  flex-direction: column
  justify-content: space-between
  padding: 8px 0

  span
    display: block
    padding-right: 16px
    vertical-align: top
    color: $gray-800
    font-size: 12px
    line-height: 24px

    i
      display: inline-block
      width: 20px
      height: 20px
      vertical-align: top
      margin-right: 8px

      svg
        width: 100%
        fill: $gray-500

.graph-chart
  display: inline
  width: 80%
  height: 250px