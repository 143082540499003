@import './../Components/variables'

.user-action-bar
  .MuiChip-root
    margin-right: 8px

  .MuiChip-filled
    background-color: $error
    color: $white
    font-size: 10px
    text-transform: uppercase