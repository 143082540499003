@import '../variables.sass'

.unauthorized-container
  .unauthorized-dialog-title
    @include titleLarge
    text-align: center

  .unauthorized-popup-container,
  h1 
    @include subtitle
    text-align: center

  .unauthorized-link 
    @include subtitle
    color: $primary-600
    cursor: pointer
    margin: 0 1%

    &:hover 
      color: $primary-700
  

